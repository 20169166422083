import React from "react"
// import {Link} from "koye"
// import {ParallaxLayer} from 'react-spring/renderprops-addons'

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Image from "../components/image"
// import Img from "koye-image"

import Logo from "../images/svg/logo4.svg";
import LiftAnimationApple from "../images/svg/lift_animation_1.svg";
import LiftAnimationPlaystore from "../images/svg/lift_animation_2.svg";

import TuneIn from "../images/tune_in.png";
import RecordEffortlessly from "../images/record_effortlessly.png";
import ReliveYourLog from "../images/relive_your_log.png";
import LiftsShowRelevance from "../images/lifts_show_relevance.png";
// import CircleTuneIn from "../images/circle_tune_in.png";
// import CircleRecord from "../images/blueprint/circle_record_effortlessly.png";
// import CircleRelive from "../images/blueprint/circle_relive_your_log.png";
// import CircleLifts from "../images/blueprint/circle_lifts_show_relevance.png";
import AppStoreWhite from "../images/app_store_icon_white.png";
import PlayStoreWhite from "../images/play_store_icon_white.png";
import Waveform from "../images/waveform.png";

import { ParallaxBanner } from 'react-scroll-parallax';
import Image, {Image2} from "../components/image";


const IndexPage = () => (
    <Layout>
        <SEO title="Koye" keywords={[`Koye`, `passive recording`, `background recording`, `social media`, `social networking`, `diary`, `journal`, `next generation`, `green`, `teal`, `smiley logo`, `microphone logo`, `two people logo`, `nearby`, `geolocation`, `serbian startup`]}/>

            <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Image2 style={{display: 'flex', flex: 1}} src={'main.png'} className={"main image-wrapper"}/>

                <div style={{position: 'absolute', top: 0, width: '100%', display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{maxWidth: 960, width: '100%', display: 'flex', flex: 1, padding: `15px`}}>
                        <div style={{width: '50%'}}/>
                        <div style={{width: '50%', display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <a style={{
                                    color: `white`,
                                    textDecoration: `none`,
                                }}
                                href="https://play.google.com/store/apps/details?id=app.koye.android"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={PlayStoreWhite} alt="" className={"icon-size button button-white margin-right-icon"}/>
                            </a>

                            <a style={{
                                    color: `white`,
                                    textDecoration: `none`,
                                }}
                                href="https://apps.apple.com/us/app/koye/id1503392571"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={AppStoreWhite} alt="" className={"icon-size button button-white"}/>
                            </a>
                        </div>
                    </div>
                    <div style={{maxWidth: 960, width: '100%', display: 'flex', flex: 1, padding: `15px`}}>
                        <div style={{width: '50%'}}/>
                        <div style={{width: '50%', textAlign: 'center', display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <LiftAnimationApple alt="" className={"icon-size margin-right-icon margin-anim-arrow"}/>
                            <LiftAnimationPlaystore alt="" className={"icon-size show-animation-arrow"}/>
                        </div>
                    </div>
                </div>

                <div style={{position: 'absolute', maxWidth: '320px', textAlign: 'center', marginTop: '-2rem'}}>
                    <Logo style={{width: '56%', padding: '0 10%', marginBottom: '4%'}}/>
                    <h1 style={{color: 'white'}}>Koye</h1>
                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={Waveform} alt="" className={"waveform"}/>
                        <h2 style={{color: 'white', margin: 'auto 7px'}}>next generation social media</h2>
                        <img src={Waveform} alt="" className={"waveform opposite"}/>
                    </div>
                </div>
            </div>

            <div style={{width: '100%', flex: 1, display: 'flex', justifyContent: 'center'}}>
                <div style={{maxWidth: 960, padding: '5% 0'}}>
                    <div style={{flex: 1, alignItems: 'center'}} className={"row"}>
                        <div className={"col-6"}>
                            <h3 className={"right"}>Tune In</h3>
                            <h4 className={"description right"}>Listen to what people near you have published. The content will be different depending on your exact location.</h4>
                        </div>
                        <div style={{position: 'relative'}} className={"col-6"}>

                            <ParallaxBanner
                                className="parallax-class"
                                layers={[
                                    {
                                        children: <Image2 src={"circle_tune_in.png"} alt="" className={"parallax-img"}/>,
                                        amount: -0.2,
                                    },
                                    {
                                        children: <Image src={TuneIn} alt="" className={"parallax-img"}/>,
                                        amount: -0.3,
                                    },
                                ]}
                                style={{height: 350, overflow: 'visible'}}
                            >
                            </ParallaxBanner>

                        </div>
                    </div>
                    <div style={{flex: 1, alignItems: 'center'}} className={"row"}>
                        <div style={{position: 'relative'}} className={"col-6 order-2"}>

                            <ParallaxBanner
                                className="parallax-class"
                                layers={[
                                    {
                                        children: <Image2 src={"circle_record_effortlessly.png"} alt="" className={"parallax-img"}/>,
                                        amount: -0.2,
                                    },
                                    {
                                        children: <Image src={RecordEffortlessly} alt="" className={"parallax-img"}/>,
                                        amount: -0.3,
                                    },
                                ]}
                                style={{height: 350, overflow: 'visible'}}
                            >
                            </ParallaxBanner>
                        </div>
                        <div className={"col-6 order-1"}>
                            <h3 className={"left"}>Record Effortlessly</h3>
                            <h4 className={"description left"}>Background recording that saves only the memorable moments. A powerful mechanism drives the selection.</h4>
                        </div>
                    </div>

                    <div style={{flex: 1, alignItems: 'center'}} className={"row"}>
                        <div className={"col-6"}>
                            <h3 className={"right"}>Relive Your Log</h3>
                            <h4 className={"description right"}>By default your moments are private, but you can choose to publish them. Each moment is a puzzle piece for you to better know your true self.</h4>
                        </div>
                        <div style={{position: 'relative'}} className={"col-6"}>

                            <ParallaxBanner
                                className="parallax-class"
                                layers={[
                                    {
                                        children: <Image2 src={"circle_relive_your_log.png"} alt="" className={"parallax-img"}/>,
                                        amount: -0.2,
                                    },
                                    {
                                        children: <Image src={ReliveYourLog} alt="" className={"parallax-img"}/>,
                                        amount: -0.3,
                                    },
                                ]}
                                style={{height: 350, overflow: 'visible'}}
                            >
                            </ParallaxBanner>
                        </div>
                    </div>

                    <div style={{flex: 1, alignItems: 'center'}} className={"row"}>
                        <div style={{position: 'relative'}} className={"col-6 order-2"}>

                            <ParallaxBanner
                                className="parallax-class"
                                layers={[
                                    {
                                        children: <Image2 src={"circle_lifts_show_relevance.png"} alt="" className={"parallax-img"}/>,
                                        amount: -0.2,
                                    },
                                    {
                                        children: <Image src={LiftsShowRelevance} alt="" className={"parallax-img"}/>,
                                        amount: -0.3,
                                    },
                                ]}
                                style={{height: 350, overflow: 'visible'}}
                            >
                            </ParallaxBanner>
                        </div>
                        <div className={"col-6 order-1"}>
                            <h3 className={"left"}>Lifts Show Relevance</h3>
                            <h4 className={"description left"}>Rank up by collecting the lifts on your published moments. The amount of lifts at the given moments is the only identification within the community.</h4>
                        </div>
                    </div>

                </div>
            </div>

    </Layout>
);

export default IndexPage
